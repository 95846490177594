function Services() {
    return   <section id="services" class="services section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Services</h2>
        <p>Check out the great services we offer</p>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon">
                <img src="/assets/img/6160001.jpg" width="100%" height="200px" />
            </div>
            <h4 class="title"><a href="">Sea Freight</a></h4>
            <p class="description">As an International Freight Forwarder and NVOCC, we handle Full Container Load, LCL or Break-Bulk Movements, Foreign to Foreign</p>
          </div>
        </div>

        <div class="col-md-12 col-lg-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
          <div class="icon-box">
            <div class="icon mb-1" >
                <img src="/assets/img/Warehouse workers loading boxes into airplane.jpg" width="100%" height="200px" />
            </div>
            <h4 class="title"><a href="">Air freight</a></h4>
            <p class="description">Airlines with multiple shipping choices are available trust us to ship in/from /to Turkey, Get complimentary suggestion & costs to ship economical, express or first flight</p>
          </div>
        </div>

        <div class="col-md-12  col-lg-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon">
                <img src="/assets/img/Loadingworkmancarryingboxes.jpg" width="100%" height="200px" />
            </div>
            <h4 class="title"><a href="">Domestic</a></h4>
            <p class="description">We provide customized solution for domestic clearing and forwarding of Break bulk, Project, and Machinery Cargo by offering door to door service to customers.</p>
          </div>
        </div>
      </div>

    </div>
  </section>
}

export default Services;