import './App.css';
import Header from './Header';
import Banner from './Banner';
import Aboutus from './Aboutus';
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Banner></Banner>
      <main id="main">
      <Aboutus></Aboutus>
      <Services></Services>
      <Contact></Contact>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
