import React, { useState } from "react";

function Contact() {
    const api_url = 'http://jalaramlogistics.com/server/api.php';
    
    const [sending, setSending] = useState(false); 
    const [contact, setContact] = useState({
        'customer_name':'',
        'customer_email_id':'',
        'email_subject':'',
        'email_content':''
    });

    const handleInput = (e) => {
        const ename = e.target.name;
        const evalue = e.target.value;
        setContact({...contact, [ename]:evalue});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSending(true);
        
        fetch(api_url, 
        { 
            method:'POST',
            body:JSON.stringify({
                'customer_name':contact.customer_name,
                'customer_email_id':contact.customer_email_id,
                'email_subject':contact.email_subject,
                'email_content':contact.email_content
            })
        })
        .then(resp => resp.json())
        .then((resp)=>{
            alert(resp.message);
            if(resp.status == 'success') {
                setContact({
                    'customer_name':'',
                    'customer_email_id':'',
                    'email_subject':'',
                    'email_content':''
                });
            }
            setSending(false);
        }).catch((err)=>{
            alert(err);
            setSending(false);
        });    
    };

    return <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

    <div class="section-title">
          <h2>Contact</h2>
         </div>

        <div class="row mt-5 justify-content-center">

          <div class="col-lg-8">

            <div class="info-wrap">
              <div class="row justify-content-center">
                <div class="col-lg-6 info">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Leena CHS Ltd, 60 Feet Road<br/>Dr. Ambedkar Road Bhayander West, Thane, Maharashtra 401101</p>
                </div>

                <div class="col-lg-6 info mt-4 mt-lg-0">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>impex@jalaramlogistics.com</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      <div class="row mt-5 justify-content-center">
        <div class="col-lg-10" data-aos="fade-up" data-aos-delay="200">
          <form onSubmit={handleSubmit} role="form" class="php-email-form">
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="name">Your Name</label>
                <input type="text" name="customer_name" class="form-control" id="name" placeholder="Your Name" required maxLength="150" value={contact.customer_name} onChange={handleInput}/>
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <label for="name">Your Email</label>
                <input type="email" class="form-control" name="customer_email_id" id="email" placeholder="Your Email" maxLength="150" required value={contact.customer_email_id} onChange={handleInput}/>
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="name">Subject</label>
              <input type="text" class="form-control" name="email_subject" id="subject" placeholder="Subject" required maxLength="150" value={contact.email_subject} onChange={handleInput}/>
            </div>
            <div class="form-group mt-3">
              <label for="name">Message</label>
              <textarea class="form-control" name="email_content" rows="10" required value={contact.email_content} onChange={handleInput}></textarea>
            </div>
            {
                (sending)?
                <div class="my-3">
                <div class="loading">Sending..</div>
                <div class="error-message"></div>
                <div class="sent-message"></div>
                </div>
            :
                <div class="text-center"><button type="submit">Send Message</button></div>
            }
          </form>
        </div>

      </div>

    </div>
  </section>
}

export default Contact;