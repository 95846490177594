function Footer() {
    return  <footer id="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 footer-contact">
                                <h5>Jalaram Logistics</h5>
                                <p> Ground Floor, A-9, <br />Leena CHS Ltd,, 60 Feet Road,<br />Dr. Ambedkar Road Bhayander West, <br />
                                Thane, Maharashtra 401101<br />
                                India <br /><br />
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#services">Services</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#contact">Contact Us</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#services">Sea Freight</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#services">Air Freight</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#services">Domestic clearing & forwarding</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container py-4">
                    <div class="copyright">
                        &copy; Copyright <strong><span>Jalaram Logistics</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
}

export default Footer;