import  React  from "react";
function Aboutus() {
    return <React.Fragment>
        <section id="what-we-do" class="what-we-do">
                <div class="container">
                    <div class="section-title">
                        <h2>What We Do</h2>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div class="icon-box">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4><a href="">Sea Freight</a></h4>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                            <div class="icon-box">
                                <div class="icon"><i class="bx bx-file"></i></div>
                                <h4><a href="">Air Freight</a></h4>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                            <div class="icon-box">
                                <div class="icon"><i class="bx bx-tachometer"></i></div>
                                <h4><a href="">Clearing & Forwarding</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section id="about" class="about">
      <div class="container">

        <div class="row">
          <div class="col-lg-6">
            <img src="assets/img/Workinprogress.gif" class="img-fluid" alt="" />
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <h3>About Us</h3>
            <p>
            We are the clearing and forwarding agents in Thane since 2021. We are involved in providing services for Trading, Ship Management and Operating, Freight Forwarding and Brokering. Our ability to develop and deliver personalized solutions across diverse and challenging projects allows our Principals.
            </p>
            <div class="row icon-boxes">
              <div class="col-md-6">
                <i class="bx bx-receipt"></i>
                <h4>Expert Team</h4>
                <p>We have a professional team working on the transfer of the most important and largest projects, our expertise extends to cover all projects that can be shipped.</p>
              </div>
              <div class="col-md-6 mt-4 mt-md-0">
                <i class="bx bx-cube-alt"></i>
                <h4>Goal Oriented</h4>
                <p>We are looking forward to be in the top of freight companies list by supporting our clients business in the first place</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    </React.Fragment>
}

export default Aboutus;